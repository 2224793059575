import * as React from 'react'
import styled from 'styled-components'
import { TiDelete } from 'react-icons/ti'

import { projectStorage } from '../../util/fbconfig'
import FlexWrapper from './components/FlexWrapper'
import useFirestore from './hooks/useFirestore'

export default function AddSampleFile({ items, setItems, item }) {
  const [sampleFiles, setSampleFiles] = React.useState([])
  const [progressValue, setProgressValue] = React.useState(0)
  const [show, setShow] = React.useState(false)
  const [hasSampleFile, setHasSampleFile] = React.useState(false)
  const { updateDocument } = useFirestore('info')

  const addSampleFile = (file, itemId) => {
    const newSampleFiles = [...sampleFiles, { id: itemId, file }]

    setSampleFiles(newSampleFiles)
    setHasSampleFile(true)
  }

  const removeSampleFile = itemId => {
    const newSampleFiles = sampleFiles.filter(
      sampleFile => sampleFile.id !== itemId
    )

    setSampleFiles(newSampleFiles)
  }

  const handleAddSampleFile = event => {
    const file = event.target.files[0]
    const newItems = items.map(currItem => {
      if (currItem.id === item.id) {
        const updatedItem = {
          ...currItem,
          sampleFileName: file.name
        }
        return updatedItem
      }
      return currItem
    })

    addSampleFile(file, item.id)
    setItems(newItems)
  }

  const handleRemove = () => {
    const newItems = items.map(currItem => {
      if (currItem.id === item.id) {
        delete currItem.sampleFileName
        if (currItem.downloadUrl !== undefined) {
          delete currItem.downloadUrl
        }
      }
      return currItem
    })

    removeSampleFile(item.id)
    setItems(newItems)
    updateDocument('submission-second-phase', { items: newItems })
  }

  const addDownloadUrl = url => {
    const newItems = items.map(currItem => {
      if (currItem.id === item.id) {
        const updatedItem = {
          ...currItem,
          downloadUrl: url
        }
        return updatedItem
      }
      return currItem
    })

    setItems(newItems)
    updateDocument('submission-second-phase', { items: newItems })
  }

  const handleUploadFile = () => {
    setShow(true)
    const currentFile = sampleFiles.find(
      sampleFile => sampleFile.id === item.id
    )
    const uploadTask = projectStorage
      .ref(`sample-files/${currentFile.file.name}`)
      .put(currentFile.file)

    uploadTask.on(
      'state_changed',
      snapshot => {
        const progress = Math.round(
          (snapshot.bytesTransferred / snapshot.totalBytes) * 100
        )

        setProgressValue(progress)
        if (progress === 100) {
          setShow(false)
        }
      },
      error => console.log(error),
      () => {
        projectStorage
          .ref('sample-files')
          .child(currentFile.file.name)
          .getDownloadURL()
          .then(url => addDownloadUrl(url))
      }
    )
    setHasSampleFile(false)
  }

  React.useEffect(() => {
    if (hasSampleFile) {
      handleUploadFile()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [hasSampleFile])

  return (
    <Wrapper>
      {item.sampleFileName === undefined ? (
        <Label htmlFor={`${item.id}-sample-file`}>
          <div>Add sample file</div>
          <input
            type="file"
            id={`${item.id}-sample-file`}
            hidden
            onChange={handleAddSampleFile}
          />
        </Label>
      ) : (
        <FlexWrapper alignItems="center">
          <div
            style={{ position: 'relative', fontSize: '0.8rem', marginTop: 5 }}
          >
            <span>
              <span style={{ color: '#3f51b5' }}>Sample file:</span>{' '}
              <span style={{ fontSize: '0.9rem' }}>{item?.sampleFileName}</span>
            </span>
            {show ? (
              <ProgressBarWrapper>
                <progress value={progressValue} max="100" />
                <span>{progressValue}%</span>
              </ProgressBarWrapper>
            ) : null}
          </div>
          <DeleteIcon
            style={{ width: '20px', height: '20px' }}
            onClick={handleRemove}
          />
        </FlexWrapper>
      )}
    </Wrapper>
  )
}

const Wrapper = styled.div`
  align-self: flex-start;
  margin-top: 12px;
  font-size: 0.7rem;
`

const Label = styled.label`
  display: flex;
  align-items: center;
  color: #3f51b5;
  background-color: #fff;
  cursor: pointer;
  padding: 5px 10px;
  border: 1px solid #3f51b5;
  border-radius: 20px;
  transition: all 0.2s ease;

  &:hover {
    background-color: #3f51b5;
    color: #fff;
  }
`

const ProgressBarWrapper = styled.div`
  position: absolute;
  left: 0;
  bottom: -15px;
  width: 100%;
  display: flex;
  align-items: center;

  progress[value] {
    height: 7px;
    margin-right: 8px;
    appearance: none;

    ::-webkit-progress-bar {
      border-radius: 20px;
      height: 5px;
      background-color: #e4e5e9;
    }

    ::-webkit-progress-value {
      border-radius: 20px;
      height: 5px;
      background-color: #3f51b5;
    }
  }
`

const DeleteIcon = styled(TiDelete)`
  margin-left: 4px;
  color: hsl(0, 94%, 60%);
  cursor: pointer;
  transition: color 0.1s ease;

  &:hover {
    color: hsl(0, 94%, 40%);
  }
`
